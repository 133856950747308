import { OrderGuideListGlobalAction, OrderGuideListGlobalStateActions, OrderGuideListState } from './types';

export const orderGuideListInitialState: OrderGuideListState = {
  ogList: [],
  loading: true,
  primaryOrderGuide: null,
};

export function reduceOrderGuideList(
  state: OrderGuideListState,
  action: OrderGuideListGlobalAction,
): OrderGuideListState {
  switch (action.type) {
    case OrderGuideListGlobalStateActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case OrderGuideListGlobalStateActions.SET_OG_LIST:
      return {
        ...state,
        ogList: action.payload.ogList,
        loading: action.payload.loading || false,
      };
    case OrderGuideListGlobalStateActions.SET_PRIMARY_ORDER_GUIDE:
      return {
        ...state,
        primaryOrderGuide: action.payload.primaryOrderGuide,
      };
    default:
      return state;
  }
}
