import { useToast } from '@chakra-ui/react';
import { Product } from '@Types/product/Product';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { GenericItem } from 'composable/analytics/generic/types';
import { ProductDataExtended } from 'composable/components/order-guide/helpers';
import { CURRENT_ALGOLIA_LOCALE } from 'composable/components/plp/constants';
import { StarterKitAlgoliaProduct } from 'composable/components/plp/types';
import { getValueByLocale } from 'composable/components/plp/utils';
import { UseUserGlobalResponse } from 'hooks/global/use_privateUserGlobal/types';
import { Hit } from 'instantsearch.js';
import { Cache } from 'swr';
import { getProductExtendedData } from 'frontastic/actions/shamrockApi';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';

export const getCategoriesFromProduct = (product: Partial<Product>): string[] => {
  if (product.categories) {
    return [...product.categories]
      .sort(function (a, b) {
        if (a.depth < b.depth) {
          return -1;
        } else if (a.depth > b.depth) {
          return 1;
        }
        return 0;
      })
      .map((c) => c.name);
  }
  return [];
};

export const buildGenericItemFromHit = (
  hit: Hit<StarterKitAlgoliaProduct>,
  productExtraData?: ExtraProductData,
  index?: number,
): GenericItem => {
  const rawPrice = productExtraData?.pricingInfo?.data?.discountedPrice;
  return {
    id: hit.sku,
    name: getValueByLocale(hit, 'name', CURRENT_ALGOLIA_LOCALE),
    index: index ? index : hit.__position,
    brand: getValueByLocale(hit.attributes, 'shamrock-standard_brand_long_description', CURRENT_ALGOLIA_LOCALE),
    price: typeof rawPrice === 'number' ? rawPrice / 100 : undefined,
    categories: hit?.categoryKeys?.['en'],
  };
};

export const buildGenericItemFromProductDataExtended = (
  productDataExtended: ProductDataExtended,
  index: number,
): GenericItem => {
  const { ctInformation, extraInformation } = productDataExtended;
  const { slug: sku, name, variants } = ctInformation;
  const rawPrice = extraInformation?.pricingInfo?.data?.discountedPrice;
  return {
    id: sku,
    name,
    index,
    brand: variants[0].attributes['shamrock-standard_brand_long_description'],
    price: typeof rawPrice === 'number' ? rawPrice / 100 : undefined,
    categories: getCategoriesFromProduct(ctInformation),
  };
};

export const getProductExtraData = async (
  skus: string[],
  user: UseUserGlobalResponse['state'],
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
  logout: (hardLogout?: boolean, message?: string) => Promise<void>,
  cache?: Cache,
): Promise<{ sku: string; productExtraData: ExtraProductData }[]> => {
  const { accessToken, activeWarehouse, activeAccount } = user;

  if (!accessToken || !activeWarehouse?.warehouseNumber || skus.length === 0) {
    return [];
  }

  const payload = {
    warehouseNumber: activeWarehouse.warehouseNumber,
    businessUnitName: activeWarehouse.businessUnit,
    businessSegmentName: activeWarehouse.businessSegment,
    customerNumber: activeAccount?.key,
    productNumbers: skus,
    includePricing: true,
    includeInventory: true,
    accessToken,
    includePurchaseHistory: {
      includeLastPurchase: true,
    },
  };

  try {
    const productExtraData = await getProductExtendedData(payload, cache);
    if (productExtraData?.products?.length > 0) {
      return productExtraData.products.map((product) => ({
        sku: product.productNumber,
        productExtraData: product,
      }));
    }
  } catch (error) {
    if (error.statusCode === 401) {
      logout(true, AuthErrorKeys.Unauthorized);
      return;
    }

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
      console.warn('Error toast shown', { reason: 'Error API call: getProductExtraData' });
    }
    console.warn('Error toast suppressed', { reason: 'Error API call: getProductExtraData' });

    return [];
  }
};

export const preciseMultiply = (number: number, times: number) => {
  return (number * 100 * times) / 100;
};
