import { Account } from '@Types/account/Account';
import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { CustomFields } from '@Types/cart/CustomFields';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { LAST_CHECKOUT_CART_ID } from 'composable/helpers/constants';
import { fetchApiHub } from 'frontastic';

export const actionGetCart = async (payload = {}, cartId?: string, retries = 10): Promise<Cart> => {
  let params = '';

  if (cartId) {
    params = `?cartId=${cartId}`;
  }

  return await fetchApiHub(
    '/action/cart/getCart' + params,
    {
      method: 'POST',
    },
    payload,
    retries,
  );
};

export const actionAddToCart = async (payload = {}, cartId?: string, retries?: number): Promise<Cart> => {
  let params = '';

  if (cartId) {
    params = `?cartId=${cartId}`;
  }
  return await fetchApiHub(
    '/action/cart/addToCart' + params,
    {
      method: 'POST',
    },
    payload,
    retries,
  );
};

export const actionUpdateCart = async (payload = {}, cartId?: string, retries?: number): Promise<Cart> => {
  let params = '';

  if (cartId) {
    // eslint-disable-next-line
    params = `?cartId=${cartId}`;
  }

  return await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
    retries,
  );
};

export const actionRemoveItem = async (payload = {}, cartId?: string, retries?: number): Promise<Cart> => {
  let params = '';

  if (cartId) {
    params = `?cartId=${cartId}`;
  }

  return await fetchApiHub(
    '/action/cart/removeLineItem' + params,
    {
      method: 'POST',
    },
    payload,
    retries,
  );
};

export const actionUpdateItem = async (payload = {}, cartId?: string, retries?: number): Promise<Cart> => {
  let params = '';

  if (cartId) {
    params = `?cartId=${cartId}`;
  }

  return await fetchApiHub(
    '/action/cart/updateLineItem' + params,
    {
      method: 'POST',
    },
    payload,
    retries,
  );
};

export const actionCheckout = async (
  payload = {},
  cartId?: string,
  retries?: number,
): Promise<{ cart: Cart; order: Order }> => {
  let params = '';

  if (cartId) {
    params = `?cartId=${cartId}`;
  }

  const res = await fetchApiHub(
    '/action/cart/checkout' + params,
    {
      method: 'POST',
    },
    payload,
    retries,
  );

  if (res?.cart?.cartId) {
    window.localStorage.setItem(LAST_CHECKOUT_CART_ID, res.cart.cartId);
  }

  return res;
};

export const actionSplitCart = async (
  payload: {
    cartId: string;
    lineItems: LineItem[];
    shipping: Address;
    billing: Address;
    account: Account;
    custom: CustomFields;
    deliveryType?: string;
    deliveryDate?: Date;
    geocode?: string;
    instructions?: string;
    poNumber?: string;
    updateOriginalCart?: any;
  },
  retries?: number,
): Promise<{ splitOrder: Order; mainOrder: Order; updatedOriginalCart: Cart }> => {
  const res = await fetchApiHub(
    '/action/cart/splitCart',
    {
      method: 'POST',
    },
    payload,
    retries,
  );

  return res;
};

export const actionGetShippingMethods = async (payload = {}, retries?: number): Promise<any> => {
  const res = await fetchApiHub(
    '/action/cart/getShippingMethods',
    {
      method: 'POST',
    },
    payload,
    retries,
  );

  return res;
};

export enum ActionCart {
  ADD_ITEM = 'ADD_ITEM',
  UPDATE_ITEM = 'UPDATE_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
}

export const SEPARATOR_FOR_QUEUE = '.';
export const generateIdForQueue = (actionCart: ActionCart, skuOrLineItem: string): string =>
  `${actionCart}${SEPARATOR_FOR_QUEUE}${skuOrLineItem}`;
