import { FEATURE_FLAG_QUEUE_LOGS } from 'composable/components/general';

const PREFIX_QUEUE = 'Log Queue: ';

export const logQueue = (message: string) => {
  if (!FEATURE_FLAG_QUEUE_LOGS) {
    return;
  }
  console.log(PREFIX_QUEUE + message);
};
