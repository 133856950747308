import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { APPLICATION_URL } from 'composable/components/general';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'composable/helpers/constants';
import {
  getServerAzureLogoutUrl,
  getServerShamrockRedirectUrl,
  GetServerShamrockRedirectUrlParams,
  refreshAccessToken,
} from 'composable/helpers/utils/use-user-utils';
import { AuthErrorKeys, ERROR_DICTIONARY } from 'helpers/constants/auth';
import routes from 'helpers/constants/routes';
import { fetchApiHub } from 'frontastic';
import { deleteLocalStorageValuesWithoutBu } from 'frontastic/actions/account';
import { ADDITIONAL_AZURE_URL_PARAMS } from '.';

/**
 * Validates accessToken is a valid string and has not expired
 * for refreshToken don't add the expiration check
 *
 * @param accessToken string accessToken or refreshToken
 * @param expiration string expiration time in unix timestamp (optional)
 * @returns boolean
 */
export function validateAccessToken(accessToken?: string, expiration?: number): boolean {
  if (expiration) {
    const expirationTime = new Date(expiration * 1000);
    const currentTime = new Date();

    if (expirationTime < currentTime) {
      return false;
    }
  }

  return !!(accessToken && accessToken.length > 5);
}

//fetch selected account details
export function selectedAccountDetails(activeAccountKey, accountList = []): Partial<AsAssociateBusinessUnitResult> {
  const accountDetails = accountList.find((bu) => bu.key === activeAccountKey) || accountList[0];
  return accountDetails;
}

export function getAdditionalAzureUrlParams(redirectUrl: string): GetServerShamrockRedirectUrlParams {
  // Parse the URL and extract the query parameters
  const urlParams = new URLSearchParams(redirectUrl.split('?')[1]);

  // Get the additional url parameters and parse it as a JSON object
  const additionalParams = urlParams.get(ADDITIONAL_AZURE_URL_PARAMS);
  const additionalParamsParsed: GetServerShamrockRedirectUrlParams = JSON.parse(additionalParams);

  return additionalParamsParsed;
}

/**
 * logout user function to be used outside of react components.
 * for react compoents use useGlobal().useUserGlobal.logout
 *
 * @param hardLogout boolean true if sessions should also be erased from azure
 * @param message string error message to identify why user logout and redirect to error page
 * @returns void
 */
export async function userLogout(hardLogout: boolean = false, message?: string) {
  if (message === AuthErrorKeys.Unauthorized || message === AuthErrorKeys.SessionExpired) {
    // try to refresh token
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

    if (validateAccessToken(refreshToken)) {
      console.info('Refreshing token for unauthorized user');
      await refreshAccessToken(refreshToken, getServerAzureLogoutUrl(), (getTokensResponse) => {
        window.localStorage.setItem(ACCESS_TOKEN, getTokensResponse.access_token);
      });

      const newAccessToken = window.localStorage.getItem(ACCESS_TOKEN);

      if (validateAccessToken(newAccessToken)) {
        console.info('Successfully refreshed token for unauthorized user');
        window.localStorage.setItem(ACCESS_TOKEN, newAccessToken);
        return;
      }
    }
  }

  if (hardLogout) {
    try {
      await fetchApiHub('/action/account/logout', { method: 'POST' });
    } catch (error) {
      console.error('Error API call: logout', error);
    }
  }

  // directly assinging the values to local storage to avoid it only be set
  // on a state refresh
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);

  if (hardLogout) {
    // If error is included in dictionary send user to error page
    if (!!message && !!ERROR_DICTIONARY[message]) {
      const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${message}`;
      window.location.href = errorPageUrl;
      return;
    }

    // otherwise, send to azure login
    deleteLocalStorageValuesWithoutBu();
    window.location.href = await getServerAzureLogoutUrl(message);
    return;
  }

  const loginRedirectUrl = await getServerShamrockRedirectUrl(APPLICATION_URL, null, message);
  window.location.href = loginRedirectUrl;
}
