import { useCallback, useReducer, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { Order } from '@Types/cart/Order';
import { OrderDetail, OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { initiaEditOrderState, reducerEditOrder } from 'hooks/global/use_privateEditOrderGlobal/reduce-edit-order';
import { EditOrderActionType, UseEditOrderGlobalProps } from 'hooks/global/use_privateEditOrderGlobal/types';
import { mountUpdateOrderPayload } from 'utils/orderEdit/mountUpdateOrderPayload';
import { updateCustomerOrderDetail } from 'frontastic/actions/shamrockApi';
import { sleep } from 'frontastic/tastics/composable/edit-order/utils';

export const use_privateEditOrderGlobal = (): UseEditOrderGlobalProps => {
  const toast = useToast();
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const [state, dispatch] = useReducer(reducerEditOrder, initiaEditOrderState);

  const enableOrderEditing = useCallback((originalOrder: OrderDetail) => {
    const editCart = { ...originalOrder, editModeTimeEnabled: new Date() };
    dispatch({
      type: EditOrderActionType.ENABLE_EDIT_ORDER,
      payload: { editCart, originalOrder },
    });
    router.push(`${routes.EDIT_ORDER_PAGE}${editCart.orderNumber}`);
  }, []);

  const disableOrderEditing = useCallback(() => {
    dispatch({ type: EditOrderActionType.DISABLE_EDIT_ORDER });
  }, []);

  const updateSplitOrder = useCallback((parentOrderNumber: string, list: Order) => {
    dispatch({ type: EditOrderActionType.SET_UPDATING_SPLIT_ORDER, payload: { parentOrderNumber, list } });
  }, []);

  const updateEditedOrder = useCallback((order: OrderDetail) => {
    dispatch({
      type: EditOrderActionType.CHANGE_ORDER_EDITING,
      payload: { editCart: order },
    });
  }, []);

  const getOriginalLineItemFromOrder = useCallback((sku: string): OrderDetailLineItem => {
    return state.originalOrder?.lineItems?.find((item) => item.productNumber === sku);
  }, []);

  const toggleOrderUpdatingState = useCallback((payload: boolean) => {
    dispatch({ type: EditOrderActionType.SET_UPDATING_ORDER, payload });
  }, []);

  const submitOrderUpdate = async () => {
    toggleOrderUpdatingState(true);

    const updateOrderPayload = mountUpdateOrderPayload(state.editCart);

    try {
      await updateCustomerOrderDetail(updateOrderPayload, toast, formatMessage({ id: 'app.generic.error' }));
    } catch (error) {
      toggleOrderUpdatingState(false);
      console.error('Error API: use_privateEditOrderGlobal', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'editOrder.checkout.toast.error' }),
          icon: TOAST_ICON.error,
        });
      }
      return;
    }

    // delay the redirect to allow the API to complete the update
    await sleep(3000);

    // If API is successful, user is taken back to the Order details
    //  with toast success message is displayed for 5 seconds
    router.push(`${routes.ORDER_DETAIL_PAGE}${state.editCart.customerNumber}/${state.editCart.orderNumber}`);

    // delay the toast to allow the page to redirect
    await sleep(1000);

    toast({
      duration: 5000,
      status: 'success',
      title: formatMessage({ id: 'editOrder.checkout.toast.success' }),
      icon: TOAST_ICON.success,
    });

    disableOrderEditing();
  };

  return {
    isEditingOrder: state.isEditingOrder,
    editCart: state.editCart,
    loading: state.loading,
    splitOrder: state.splitOrder,
    originalOrder: state.originalOrder,
    updateSplitOrder,
    enableOrderEditing,
    disableOrderEditing,
    updateEditedOrder,
    submitOrderUpdate,
    getOriginalLineItemFromOrder,
    toggleOrderUpdatingState,
  };
};
