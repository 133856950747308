import { ProductDataExtended } from 'composable/components/order-guide/helpers';

export enum OrderGuideFilteredTags {
  JIT = 'JIT',
  SUR = 'SUR',
  XDK = 'XDK',
}

export const filterOrderGuideByTags = (product: ProductDataExtended, filteredTagOptions: OrderGuideFilteredTags[]) => {
  const isJustInTime = product.extraInformation.companyInfo.data.isJustInTime;
  const isShipUponReceipt = product.extraInformation.companyInfo.data.isShipUponReceipt;
  const isCrossdock = product.extraInformation.companyInfo.data.isCrossdock;

  if (
    filteredTagOptions.includes(OrderGuideFilteredTags.SUR) &&
    filteredTagOptions.includes(OrderGuideFilteredTags.XDK) &&
    filteredTagOptions.includes(OrderGuideFilteredTags.JIT)
  ) {
    return isShipUponReceipt || isCrossdock || isJustInTime;
  }

  if (filteredTagOptions.includes(OrderGuideFilteredTags.JIT)) {
    return isJustInTime;
  }

  if (
    filteredTagOptions.includes(OrderGuideFilteredTags.SUR) &&
    filteredTagOptions.includes(OrderGuideFilteredTags.XDK)
  ) {
    return isShipUponReceipt || isCrossdock;
  }

  return !filteredTagOptions.length;
};
