export function getProductsMaxLeadDays(extraProductData: any[]) {
  const productsleadDays = extraProductData?.map((product) => {
    const cutoffs = product.companyInfo?.data?.cutoffs?.daysOfWeek ?? [];
    if (cutoffs.length > 0) {
      const cutoffLeadDays = cutoffs.map((cutoff) => cutoff.leadDays);
      return Math.abs(Math.max(...cutoffLeadDays));
    }
    return 0;
  });

  if (productsleadDays?.length > 0) {
    return Math.max(...productsleadDays);
  }

  return 0;
}
