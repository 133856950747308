import { Dispatch } from 'react';
import { OrderGuideMultipleCustomers } from 'composable/components/order-guide-management';

export type OrderGuideListState = {
  ogList: OrderGuideMultipleCustomers[];
  loading: boolean;
  primaryOrderGuide: string | null;
};

export enum OrderGuideListGlobalStateActions {
  SET_LOADING = 'SET_LOADING',
  SET_OG_LIST = 'SET_OG_LIST',
  SET_PRIMARY_ORDER_GUIDE = 'SET_PRIMARY_ORDER_GUIDE',
}

export type OrderGuideListGlobalAction = {
  type: OrderGuideListGlobalStateActions;
  payload?: Partial<OrderGuideListState>;
};

export type UseOrderGuideListGlobalResponse = {
  state: OrderGuideListState;
  dispatch: Dispatch<OrderGuideListGlobalAction>;
  listOrderGuides: () => Promise<OrderGuideMultipleCustomers[]>;
};
