import { HStack, Text } from '@chakra-ui/react';
import { SU_ACCOUNT_INFORMATION, SU_ACCOUNT_NAME } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { FunctionComponent } from 'react';
import { INITIAL_ACCOUNT_INFORMATION, SUPER_USER_BANNER_ID } from './constants';
import { getImpersonateUserIdAndUserName } from './helpers/utils';
import { useFormat } from 'helpers/hooks';
import { useGlobal } from 'components/globalProvider';
import { useRouter } from 'next/router';
import { isSuperUserPage as isSuperUserPageCb } from 'helpers/slugHelpers';
import routes from 'helpers/constants/routes';

type SuperUserBannerProps = {
  isPublic: boolean;
};

export const SuperUserBanner: FunctionComponent<SuperUserBannerProps> = ({ isPublic }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const {
    state: { loading },
  } = useGlobal().useUserGlobal;
  const [combinedStringUserName] = useLocalStorage(SU_ACCOUNT_NAME, '');
  const [accountInformation] = useLocalStorage(SU_ACCOUNT_INFORMATION, INITIAL_ACCOUNT_INFORMATION);
  const { userName } = getImpersonateUserIdAndUserName(combinedStringUserName);
  const router = useRouter();
  const isSuperUserPage = isSuperUserPageCb(router.asPath);

  if (loading || !userName || !accountInformation.accountName || isSuperUserPage || isPublic) {
    return null;
  }

  return (
    <HStack
      top={0}
      position={'sticky'}
      zIndex={999999999}
      id={SUPER_USER_BANNER_ID}
      width="full"
      color="white"
      backgroundColor="primary.700"
      fontSize={{ base: 'xs', lg: 'sm' }}
      lineHeight="150%"
      align="center"
      justify="center"
      textAlign="center"
      px={10}
      gap={2}
      py={2}
    >
      <Text>
        {formatMessage({
          id: 'superUser.banner',
          values: {
            userName: userName || '',
            accountName: accountInformation.accountName || '',
          },
        })}
        <Text
          as="span"
          textDecoration="underline"
          cursor="pointer"
          onClick={() => {
            localStorage.removeItem(SU_ACCOUNT_NAME);
            router.push(routes.SUPER_USER_ACCOUNTS);
          }}
          mx={0.5}
        >
          {formatMessage({ id: 'superUser.banner.changeAccount' })}
        </Text>
        <Text as="span" mr={0.5}>
          |
        </Text>
        <Text
          as="span"
          textDecoration="underline"
          cursor="pointer"
          onClick={() => {
            localStorage.removeItem(SU_ACCOUNT_NAME);
            router.push(routes.SUPER_USER_ACCOUNTS_NUMBER);
          }}
        >
          {formatMessage({ id: 'superUser.banner.changeUsername' })}
        </Text>
      </Text>
    </HStack>
  );
};
