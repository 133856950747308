import { Dispatch } from 'react';
import { Account } from '@Types/account/Account';
import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { CustomFields } from '@Types/cart/CustomFields';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { Money } from '@Types/product/Money';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { ProductDataExtended } from 'composable/components/order-guide/helpers';
import { CartDetails } from 'frontastic/actions/cart';
import { UserState } from '../use_privateUserGlobal/types';

export type ProductGroup = {
  [key: string]: ProductDataExtended[];
};

export type CartState = {
  cart?: Cart;
  shippingMethods?: ShippingMethod[];
  loading: boolean;
  error: Error | null;
};

export enum CartGlobalStateActions {
  UPDATE = 'UPDATE',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
}

export type CartGlobalAction = {
  type: CartGlobalStateActions;
  payload?: Partial<CartState>;
};

export type UseCartGlobalParams = {
  user?: UserState;
  isPublicPage?: boolean;
};

export type UseCartGlobalResponse = {
  cart?: Cart;
  loading: boolean;
  shippingMethods?: ShippingMethod[];
  error?: Error;
  mdCartResponseTime?: number;
  //Queue
  shouldAddToQueue?: boolean;
  removeActionFromQueue?: (id: string) => boolean;
  dispatch: Dispatch<CartGlobalAction>;
  fetchCart: (cartId?: string) => Promise<Cart>;
  addItem: (items: AddItemParams[], cartId?: string) => Promise<Cart>;
  updateCartDetails: (cartDetails: CartDetails) => Promise<Cart>;
  removeItem: (lineItem: Partial<LineItem>, cartId?: string) => Promise<Cart>;
  updateItem: (lineItem: Partial<LineItem>, quantity: number, price: Money, cartId?: string) => Promise<Cart>;
};

export type AddItemParams = {
  product?: Product;
  variant: Variant;
  quantity: number;
  price: Money;
};

export type CheckoutCart = {
  lineItems?: LineItem[];
  deliveryType?: string;
  deliveryDate?: Date;
  geocode?: string;
  poNumber?: string;
  instructions?: string;
  shippingAddress?: Address;
  billingAddress?: Address;
  account?: Account;
  productGroups?: ProductGroup[];
  deliveryDates?: string[];
  customFields?: CustomFields;
};

export type CheckoutUpdateDetails = {
  orderDetails?: CartDetails;
  splitOrderDetails?: CartDetails;
};

export type CheckoutResponse = {
  order: Order;
  splitOrder?: Order;
};
