import { Dispatch } from 'react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { CustomerProps, WarehouseProps } from 'composable/helpers/utils/use-user-utils/types';
import { ShamrockUser } from 'frontastic/actions/shamrockApi/types';

export type UserState = {
  accessToken?: string;
  refreshToken?: string;
  expiresOn?: number;
  ctUser?: CustomerProps;
  shamrockUser?: ShamrockUser;
  activeAccount?: Partial<AsAssociateBusinessUnitResult>;
  activeWarehouse?: WarehouseProps;
  accountList?: AsAssociateBusinessUnitResult[];
  loading: boolean;
  isSuperUser?: boolean;
  loggedAsSuperUser?: boolean;
};

export enum UserGlobalStateActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_LOADING = 'SET_LOADING',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_IS_SUPER_USER = 'SET_IS_SUPER_USER',
  SET_LOGGED_AS_SUPER_USER = 'SET_LOGGED_AS_SUPER_USER',
}

export type UserGlobalAction = {
  type: UserGlobalStateActions;
  payload?: Partial<UserState>;
};

export interface UseUserGlobalResponse {
  state: UserState;
  dispatch: Dispatch<UserGlobalAction>;
  checkIfLoggedIn: () => Promise<void>;
  logout: (hardLogout?: boolean, message?: string) => Promise<void>;
  switchAccount: (accountKey: string) => Promise<void>;
  impersonateUser: (accessToken: string, accountKey: string, impersonatedUserId: string) => Promise<void>;
  canViewProductCatalog: () => boolean;
  azureRedirectUrl: string;
}
