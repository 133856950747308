import { ExtraProductData } from '@Types/shamrockApi/Product';
import { addDays, isAfter, setHours, setMinutes, setSeconds } from 'date-fns';

export function calculateLatestDeliveryDate(extraProductDataList: ExtraProductData[]) {
  let latestDate: Date = null;

  (extraProductDataList ?? []).forEach((extraData) => {
    const cutoffs = extraData?.companyInfo?.data?.cutoffs;
    // Only calculate date for products with lead time
    if ((cutoffs?.daysOfWeek?.length ?? 0) > 0) {
      let deliveryDate = new Date();
      const currentDayOfWeek = deliveryDate.getDay(); // Get the current day of the week (0 for Sunday, 6 for Saturday)

      // Add the lead days to the current date
      const leadDays = Math.abs(cutoffs.daysOfWeek[currentDayOfWeek]?.leadDays || 0);
      deliveryDate = addDays(deliveryDate, leadDays + 1); // Add 1 day to the lead days to get the next day

      // Handle cutoff time
      const cutoffDayOfWeek = deliveryDate.getDay();
      let cutoff = addDays(deliveryDate, cutoffs.daysOfWeek[cutoffDayOfWeek]?.leadDays || 0);

      const cutoffTimeArray = cutoffs.daysOfWeek[cutoffDayOfWeek]?.cutoffTime.split(':') || ['0', '0', '0'];
      cutoff = setHours(cutoff, parseInt(cutoffTimeArray[0], 10));
      cutoff = setMinutes(cutoff, parseInt(cutoffTimeArray[1], 10));
      cutoff = setSeconds(cutoff, parseInt(cutoffTimeArray[2], 10));

      // Compare the cutoff time with the current time
      if (isAfter(new Date(), cutoff)) {
        //TODO if past cutoff we should recalculate because the lead days for tomorrow might not be the same
        deliveryDate = addDays(deliveryDate, 1);
      }

      // Update the latest delivery date if it's later
      if (!latestDate || isAfter(deliveryDate, latestDate)) {
        latestDate = deliveryDate;
      }
    }
  });

  latestDate = latestDate ?? new Date();

  const utcMidnightDate = new Date(
    Date.UTC(latestDate.getUTCFullYear(), latestDate.getUTCMonth(), latestDate.getUTCDate(), 0, 0, 0, 0),
  );

  return utcMidnightDate;
}
