import { AuthErrorKeys } from 'helpers/constants/auth';
import { userLogout } from 'hooks/global/use_privateUserGlobal/utils';
import { AbortError } from 'p-retry';
import { FrontasticError } from './FrontasticError';
import { ResponseError } from '../fetch-api-hub';

enum ERROR_CODES {
  ECONNRESET = 'ECONNRESET',
  NOT_AUTHORIZED = 'User not authenticated',
}

type ShamrockError = {
  code?: string;
  message?: string;
};

export const handleShamrockErrors = (error: object | string, errObj: ResponseError | undefined) => {
  const errorStatus = errObj?.getStatus();
  if (errorStatus === 401) {
    userLogout(true, AuthErrorKeys.Unauthorized);
    return;
  }
  if (errorStatus >= 500) {
    const message = 'Unexpected Error';
    throw new AbortError(new FrontasticError(message, errorStatus));
  }
  if (typeof error === 'string') {
    return;
  }
  const shamrockError = error as ShamrockError;
  if (shamrockError.code && shamrockError.code === ERROR_CODES.ECONNRESET) {
    //TODO define a better string than 'generic'
    //This is the way to force the retry
    throw new Error(`Error on Shamrock API: ${shamrockError.message || 'generic'}`);
  }
};
