import { EditOrderAction, EditOrderActionType, EditOrderState } from 'hooks/global/use_privateEditOrderGlobal/types';

export const initiaEditOrderState: EditOrderState = {
  isEditingOrder: false,
  editCart: null,
  originalOrder: null,
  loading: false,
  splitOrder: null,
};

export const reducerEditOrder = (state: EditOrderState, action: EditOrderAction): EditOrderState => {
  switch (action.type) {
    case EditOrderActionType.ENABLE_EDIT_ORDER:
      return {
        ...state,
        isEditingOrder: true,
        editCart: action.payload.editCart,
        originalOrder: action.payload.originalOrder,
      };
    case EditOrderActionType.DISABLE_EDIT_ORDER:
      return {
        ...state,
        isEditingOrder: false,
        editCart: undefined,
        originalOrder: undefined,
        loading: false,
      };
    case EditOrderActionType.CHANGE_ORDER_EDITING:
      return {
        ...state,
        editCart: action.payload.editCart,
      };
    case EditOrderActionType.SET_UPDATING_ORDER:
      return {
        ...state,
        loading: action.payload,
      };
    case EditOrderActionType.SET_UPDATING_SPLIT_ORDER:
      return {
        ...state,
        splitOrder: action.payload,
      };
    default:
      return state;
  }
};
