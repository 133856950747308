/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { Money } from '@Types/product/Money';
import {
  analyticsTrackAddToCart,
  analyticsTrackRemoveFromCart,
  analyticsTrackUpdateCart,
} from 'composable/analytics/analytics-event-tracking';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { useFormat } from 'helpers/hooks';
import { useSubmittedCartHandler } from 'helpers/hooks/useSubmittedCartHandler';
import { useQueue } from 'hooks/useQueue';
import { CartDetails } from 'frontastic/actions/cart';
import { cartInitialState, reduceCart } from './reduce-cart';
import { AddItemParams, CartGlobalStateActions, UseCartGlobalParams } from './types';
import {
  actionAddToCart,
  actionGetCart,
  actionRemoveItem,
  actionUpdateCart,
  actionGetShippingMethods,
  actionUpdateItem,
  generateIdForQueue,
  ActionCart,
} from './utils';

export const use_privateCartGlobal = ({ user = null, isPublicPage = false }: UseCartGlobalParams) => {
  const [state, dispatch] = useReducer(reduceCart, cartInitialState);
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const toastMessage = formatMessage({ id: 'app.generic.error' });
  const [mdCartResponseTime, setMdCartResponseTime] = useState<number>(0);
  const fireGenericToastError = () => {
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
  };

  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);
  const isUserLoading = user?.loading;

  const {
    addActionToQueue,
    removeActionFromQueue,
    shouldAddToQueue,
    showUsavedChanges,
    setShowUnsavedChanges,
    redirectWithoutSaving,
  } = useQueue();

  const fetchCart = useCallback(
    async (cartId?: string): Promise<Cart> => {
      if (isUserLoading || isPublicPage || !user.activeAccount?.key) {
        return;
      }
      try {
        const startTime = performance.now();
        const response = await actionGetCart({}, cartId);

        const endTime = performance.now();
        const responseTime = endTime - startTime;
        setMdCartResponseTime(responseTime);

        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        return response;
      } catch (error) {
        console.error('GLOBAL Cart error: fetchCart', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, user?.activeAccount?.key],
  );
  const { resetSubmittedCart } = useSubmittedCartHandler(state.cart, fetchCart);

  const addItem = useCallback(
    async (items: AddItemParams[], cartId?: string): Promise<Cart> => {
      const executeAction = async () => {
        const payload = items.map((item) => ({
          variant: {
            sku: item.variant.sku,
            quantity: item.quantity,
            externalPrice: item.price,
            value: item.price,
          },
        }));

        const response = await actionAddToCart(payload, cartId);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }
        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });

        payload.forEach((item) => {
          analyticsTrackAddToCart({
            sku: item.variant.sku,
            quantity: item.variant.quantity,
            nextCart: response,
          });
        });

        return response;
      };

      try {
        if (shouldAddToQueue) {
          const skus = items.map((item) => item.variant.sku).join('');
          const idAction = generateIdForQueue(ActionCart.ADD_ITEM, skus);
          addActionToQueue({
            id: idAction,
            action: () => executeAction(),
          });
          return;
        }

        return await executeAction();
      } catch (error) {
        console.error('GLOBAL Cart error: addItem', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, shouldAddToQueue],
  );

  const updateCartDetails = useCallback(
    async (cartDetails: CartDetails): Promise<Cart> => {
      try {
        const payload = { ...cartDetails };
        const response = await actionUpdateCart(payload);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }
        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        return response;
      } catch (error) {
        console.error('GLOBAL Cart error: updateCartDetails', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading],
  );

  const removeItem = useCallback(
    async (lineItem: Partial<LineItem>, cartId?: string): Promise<Cart> => {
      const executeAction = async () => {
        const payload = {
          lineItem: { ...lineItem, id: lineItem.lineItemId },
        };

        const response = await actionRemoveItem(payload, cartId);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }
        analyticsTrackRemoveFromCart({
          lineItemId: lineItem.lineItemId,
          prevCart: state.cart,
          nextCart: response,
        });

        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        return response;
      };

      try {
        if (shouldAddToQueue) {
          const idAction = generateIdForQueue(ActionCart.REMOVE_ITEM, lineItem.lineItemId);
          addActionToQueue({
            id: idAction,
            action: () => executeAction(),
          });
          return;
        }

        return await executeAction();
      } catch (error) {
        console.error('GLOBAL Cart error: removeItem', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, shouldAddToQueue, state.cart],
  );

  const updateItem = useCallback(
    async (lineItem: Partial<LineItem>, quantity: number, price: Money, cartId?: string): Promise<Cart> => {
      const executeAction = async () => {
        const payload = {
          lineItem: {
            id: lineItem.lineItemId,
            count: quantity,
            externalPrice: price,
          },
        };

        const response = await actionUpdateItem(payload, cartId);
        if (!response.cartId) {
          resetSubmittedCart();
          return;
        }

        analyticsTrackUpdateCart({
          lineItemId: lineItem.lineItemId,
          quantity: quantity,
          prevCart: state.cart,
          nextCart: response,
        });

        await dispatch({ type: CartGlobalStateActions.UPDATE, payload: { cart: response } });
        return response;
      };

      try {
        if (shouldAddToQueue) {
          const idAction = generateIdForQueue(ActionCart.UPDATE_ITEM, lineItem.lineItemId);
          addActionToQueue({
            id: idAction,
            action: () => executeAction(),
          });
          return;
        }

        return await executeAction();
      } catch (error) {
        console.error('GLOBAL Cart error: updateItem', error);
        fireGenericToastError();
        dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isUserLoading, shouldAddToQueue, state.cart],
  );

  const fetchShippingMethods = useCallback(async () => {
    if (isUserLoading || isPublicPage || !user.activeAccount?.key) {
      return;
    }
    try {
      const methods = await actionGetShippingMethods();
      setShippingMethods(methods ?? []);
    } catch (error) {
      console.error('GLOBAL Cart error: fetchShippingMethods', error);
      fireGenericToastError();
      dispatch({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
    }
  }, [isUserLoading, user.activeAccount?.key]);

  //get shipping methods
  useEffect(() => {
    if (isUserLoading || isPublicPage || !user.activeAccount?.key) {
      return;
    }

    fetchCart();
    fetchShippingMethods();
  }, [isUserLoading, user.activeAccount?.key]);

  return {
    ...state,
    shippingMethods,
    dispatch,
    fetchCart,
    addItem,
    updateCartDetails,
    removeItem,
    updateItem,
    shouldAddToQueue,
    removeActionFromQueue,
    showUsavedChanges,
    setShowUnsavedChanges,
    redirectWithoutSaving,
    mdCartResponseTime,
  };
};
