import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  ThemingProps,
  VStack,
} from '@chakra-ui/react';
import { FunctionComponent, KeyboardEvent, PropsWithChildren, useEffect, useRef } from 'react';

export type CustomModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  textUnfilledButton?: string;
  onClickUnfilledButton?: () => void;
  textFilledButton?: string;
  onClickFilledButton?: () => void;
  showFooter?: boolean;
  bodyProps?: BoxProps;
  headerProps?: BoxProps;
  filledButtonProps?: ButtonProps;
  showCloseButton?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  modalProps?: ThemingProps;
  modalContentProps?: ModalContentProps;
  autoFocusFilledButton?: boolean;
};

export const CustomModal: FunctionComponent<PropsWithChildren<CustomModalProps>> = ({
  children,
  isOpen,
  onClose,
  title,
  subtitle,
  textFilledButton,
  textUnfilledButton,
  onClickUnfilledButton,
  onClickFilledButton,
  showFooter = true,
  bodyProps,
  headerProps,
  filledButtonProps,
  showCloseButton = true,
  isLoading = false,
  loadingText,
  modalProps,
  modalContentProps,
  autoFocusFilledButton = true,
}) => {
  const filledButtonRef = useRef(null);

  useEffect(() => {
    if (isOpen && autoFocusFilledButton) {
      setTimeout(() => {
        if (filledButtonRef.current) {
          filledButtonRef.current.focus();
        }
      }, 10);
    }
  }, [isOpen, autoFocusFilledButton]);

  return (
    <Modal
      isCentered
      size={{ base: 'md', md: 'xxl' }}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="610px"
        padding={{ base: 5, md: 8 }}
        containerProps={{
          paddingX: {
            base: 2.5,
            md: 'inherit',
          },
        }}
        {...modalContentProps}
      >
        <ModalHeader
          p={0}
          mb={{ base: 5, md: 10 }}
          textTransform="capitalize"
          fontSize={{ base: 'lg', sm: 'sm', md: 'xxl' }}
          {...headerProps}
        >
          <VStack gap={5} align="start">
            <Text fontWeight="bold" color="neutral.900" lineHeight="120%">
              {title}
            </Text>
            {subtitle && (
              <Text fontSize={{ base: 'sm', md: 'base' }} textStyle="Desktop/Body-Default">
                {subtitle}
              </Text>
            )}
          </VStack>
        </ModalHeader>
        {showCloseButton && (
          <ModalCloseButton
            color="neutral.900"
            mt={{ base: 2, sm: 'initial' }}
            _focus={{ outline: 'none' }}
            _hover={{ bg: 'transparent' }}
            _focusVisible={{
              outlineColor: 'violet.400',
              outlineWidth: '2px',
              outlineStyle: 'solid',
              outlineOffset: '0px',
              boxShadow: 'none',
              borderRadius: '6px',
            }}
          />
        )}
        {children ? (
          <ModalBody p={0} mb={10} {...bodyProps}>
            {children}
          </ModalBody>
        ) : null}
        {showFooter && (
          <ModalFooter p={0} gap={4}>
            {isLoading && loadingText ? (
              <Flex gap={2} flexGrow={1}>
                <Spinner w={4} h={4} size={'sm'} thickness="2px" color="primary.500" marginTop={1} />
                <Text>{loadingText}</Text>
              </Flex>
            ) : (
              <>
                {!!textUnfilledButton && (
                  <Button
                    flex={1}
                    variant="ds-ghost-on-light"
                    bgColor="#fff"
                    onClick={onClickUnfilledButton}
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      if (e.key === 'Enter') {
                        onClickUnfilledButton();
                      }
                    }}
                    isDisabled={isLoading}
                    size="ds-lg"
                    w="full"
                  >
                    {textUnfilledButton}
                  </Button>
                )}
                <Button
                  ref={filledButtonRef}
                  flex={1}
                  variant="ds-filled"
                  size="ds-lg"
                  width="full"
                  isDisabled={isLoading}
                  onClick={onClickFilledButton}
                  onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                    if (e.key === 'Enter') {
                      onClickFilledButton();
                    }
                  }}
                  isLoading={isLoading}
                  {...filledButtonProps}
                >
                  {textFilledButton}
                </Button>
              </>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
