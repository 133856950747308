import routes from 'helpers/constants/routes';

export const PUBLIC_PREFIX = '/public';
export const SUPER_USER_PREFIX = '/su';
export const isPublicSlug = (slug: string) => slug.includes(PUBLIC_PREFIX);
export const isSuperUserPage = (slug: string) => slug.startsWith(SUPER_USER_PREFIX);

export const isSlugValid = (slug: string) => {
  for (let path in routes) {
    const pathValue = routes[path].toLowerCase();

    const canRedirectConditions =
      pathValue !== routes.HOME &&
      pathValue !== routes.NOT_FOUND &&
      pathValue !== routes.SUPER_USER_ACCOUNTS &&
      slug.includes(pathValue) &&
      !isPublicSlug(slug);

    if (canRedirectConditions) {
      return true;
    }
  }
  return false;
};
