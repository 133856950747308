import { useCallback, useEffect, useReducer } from 'react';
import { getDeliveryOptionsSoftCutoffs } from 'frontastic/actions/shamrockApi';
import { cutoffsInitialState, reduceCutoffs } from './reduce-cutoffs';
import { CutoffsGlobalStateActions, UseCutoffsGlobalParams } from './types';

export const use_privateCutoffsGlobal = ({ user, isPublic }: UseCutoffsGlobalParams) => {
  const [state, dispatch] = useReducer(reduceCutoffs, cutoffsInitialState);

  const userIsReady = () => {
    return !isPublic && !!user && !user?.loading && !!user.activeAccount?.key && !!user.accessToken;
  };

  const loadSoftCutoffs = useCallback(async () => {
    if (!userIsReady()) {
      return;
    }

    dispatch({ type: CutoffsGlobalStateActions.SET_LOADING, payload: { loading: true } });

    // Soft Cutoffs API call
    try {
      const response = await getDeliveryOptionsSoftCutoffs(user.accessToken, {
        warehouseNumber: user.activeWarehouse?.warehouseNumber,
        businessUnitName: user.activeWarehouse?.businessUnit,
        businessSegmentName: user.activeWarehouse?.businessSegment,
        customerNumber: user.activeAccount?.key,
      });

      dispatch({
        type: CutoffsGlobalStateActions.SET_SOFT_CUTOFFS,
        payload: { softCutoffs: response, loading: false },
      });
    } catch (error) {
      console.error('API Error soft cutoffs', error);
    } finally {
      dispatch({ type: CutoffsGlobalStateActions.SET_LOADING, payload: { loading: false } });
    }
  }, [user?.activeAccount?.key]);

  useEffect(() => {
    if (!userIsReady()) {
      return;
    }

    loadSoftCutoffs();
  }, [user?.loading, isPublic]);

  return { ...state, dispatch };
};
