import { createContext, useContext, useState } from 'react';
import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { useRefreshToken } from 'hooks/useRefreshToken';
import {
  addItem,
  addPaymentByInvoice,
  checkout,
  getCheckoutOrder,
  getOrder,
  getProjectSettings,
  orderHistory,
  redeemDiscountCode,
  removeDiscountCode,
  removeItem,
  setShippingMethod,
  updateCart,
  updateItem,
  splitCart,
  emptyCartValue,
} from 'frontastic/actions/cart';
import { Tastic } from 'frontastic/lib/types';
import DarkModeProvider from 'frontastic/provider/dark-mode';
import { UseCart } from 'frontastic/provider/Frontastic/UseCart';

export interface ComposableContextInterface {
  cartDrawer: UseDisclosureReturn;
  megaDrawer: UseDisclosureReturn;
  additionalMenuItems: Tastic[] | null;
  setAdditionalMenuItems: (payload: Tastic[]) => void;
  useCart: UseCart;
}

const ComposableContext = createContext<ComposableContextInterface | undefined>(undefined);

export type ComposableProviderProps = Partial<ComposableContextInterface> & {
  children: JSX.Element | JSX.Element[];
  isPublic?: boolean;
};

export const ComposableProvider = ({ children }: ComposableProviderProps) => {
  useRefreshToken();
  const {
    logout,
    state: { loading: isLoadingUser },
  } = useGlobal().useUserGlobal;

  const cartDrawer = useDisclosure();
  const megaDrawer = useDisclosure();

  const [additionalMenuItems, setAdditionalMenuItems] = useState<Tastic[] | null>(null);

  // avoid calling shippingMethods from composable Provider, using only from globalCart
  const shippingMethodsValue = null;
  const cartItemsWithExtraData = emptyCartValue();

  const useCart = {
    ...cartItemsWithExtraData,
    addItem,
    updateCart,
    removeItem,
    updateItem,
    splitCart,
    shippingMethods: shippingMethodsValue,
    setShippingMethod,
    checkout,
    orderHistory,
    getProjectSettings,
    redeemDiscountCode,
    removeDiscountCode,
    getOrder,
    getCheckoutOrder,
    addPaymentByInvoice,
  };

  if (useCart?.error?.statusCode === 401 && !isLoadingUser) {
    logout(true, AuthErrorKeys.SessionExpired);
  }

  return (
    <DarkModeProvider>
      <ComposableContext.Provider
        value={{
          useCart,
          cartDrawer,
          megaDrawer,
          additionalMenuItems,
          setAdditionalMenuItems,
        }}
      >
        {children}
      </ComposableContext.Provider>
    </DarkModeProvider>
  );
};

export const useComposable = () => {
  const context = useContext(ComposableContext);
  checkContext(context);
  return context;
};

console.warn('Deprecated provider ComposableProvider');
const checkContext = (context: ComposableContextInterface) => {
  if (!context) {
    throw new Error('Expected to be wrapped in ComposableProvider');
  }
};

export const useCart = () => {
  const context = useContext(ComposableContext);
  checkContext(context);
  return context.useCart;
};
