import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { Cart } from '@Types/cart/Cart';
import { CART_HAS_BEEN_SUBMITTED_ID, TOAST_ICON } from 'composable/components/general';
import { SELECTED_BUSINESS_UNIT_KEY, IS_SPLIT_ORDER } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { FeatureFlags } from 'utils/featureFlags';
import { useFormat } from './useFormat';
import { isSuperUserPage as isSuperUserPageFn } from 'helpers/slugHelpers';

export const useSubmittedCartHandler = (cart: Cart | null, refetchCart: (cartId?: string) => Promise<Cart>) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const cartId = useRef<string | null>(cart?.cartId);
  const lineItems = cart?.lineItems;
  const [selectedBU] = useLocalStorage(SELECTED_BUSINESS_UNIT_KEY);
  const activeAccount = useRef(selectedBU);
  const { asPath } = useRouter();
  const isSuperUserPage = isSuperUserPageFn(asPath);

  function fireToast() {
    const isSplitOrder = window.localStorage.getItem(IS_SPLIT_ORDER);
    if (
      FeatureFlags.TOAST_CART_SUBMITTED_BY_SOMEONE_ELSE &&
      !toast.isActive(CART_HAS_BEEN_SUBMITTED_ID) &&
      !isSuperUserPage &&
      !isSplitOrder
    ) {
      toast({
        duration: 5000,
        id: CART_HAS_BEEN_SUBMITTED_ID,
        status: 'warning',
        title: formatMessage({ id: 'cart.hasBeenSubmitted' }),
        icon: TOAST_ICON.warning,
      });
    }
  }

  async function resetSubmittedCart() {
    fireToast();
    const newCart = await refetchCart();
    cartId.current = newCart?.cartId;
  }

  useEffect(() => {
    if (cart?.cartId && cart.cartId !== cartId.current) {
      cartId.current = cart.cartId;
    }
  }, [cart?.cartId]);

  useEffect(() => {
    if (selectedBU !== activeAccount.current) {
      activeAccount.current = selectedBU;
      return;
    }
    if (lineItems?.length > 0) {
      console.log(cartId.current);
    }
    refetchCart(cartId.current).then((res) => {
      if (Boolean(res?.cartId) && res?.cartId !== cartId.current) {
        resetSubmittedCart();
      }
    });
  }, [asPath]);

  return { resetSubmittedCart };
};
