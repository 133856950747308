import { AxiosError } from 'axios';
import { AuthErrorKeys } from 'helpers/constants/auth';

export const handleAxiosError = async (
  error: AxiosError,
  logout: (hardLogout?: boolean, error_message?: string) => Promise<void>,
) => {
  if ([401, 403].includes(error?.response?.status)) {
    await logout(true, AuthErrorKeys.SessionExpired);
  }
};
